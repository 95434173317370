(function ($, prodcat, site) {
  var lastIndex = 0;
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
  var isMediumUp = parseInt(bp.width, 10) >= parseInt(bps.medium, 10);

  Drupal.behaviors.productImageFullV1 = {
    // @todo move this to private scope inside the attach.
    initCarousel: function ($fsCarousel, $tCarousel, $container, imageZoom, $module) {
      var $dotsDiv = $('.carousel-dots', $container);
      var sliderThumbSelector = '.js-product-thumb';
      var $sliderThumbs = $(sliderThumbSelector, $container);
      var $sppThumbnails = $('.js-product-thumbs', $container);
      var $productBadge = $('.js-product-full-badge', $module);
      var fsSettings = {
        appendDots: $dotsDiv,
        dots: true,
        arrows: false,
        infinite: true,
        slide: '.js-spp-carousel__slide',
        slidesToShow: 1,
        slidesToScroll: 1
      };

      var _init_slides_carousel = function () {
        var $carousel = $fsCarousel.$ele;

        $carousel.on('init afterChange', function () {
          $(window).trigger('reInitZoom');
        });

        $carousel.not('.slick-initialized').slick(fsSettings);
        $(sliderThumbSelector, $sppThumbnails).first().addClass('js-main-image-thumb');
        $carousel.slick('slickFilter', ':not(.hidden)');
        if (lastIndex) {
          $carousel.slick('slickGoTo', lastIndex);
          lastIndex = 0;
        }

        // When a user swipes on the main image, we want to update the thumbnails as well
        $carousel.on('afterChange', function (event, slick, currentSlide) {
          $sliderThumbs
            .each(function () {
              $(this).removeClass('active');
            })
            .eq(currentSlide)
            .addClass('active');

          $sliderThumbs
            .parent()
            .find('[data-slick-index="' + currentSlide + '"]')
            .trigger('click');
        });

        // Show badge only for the first image.
        $carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          var $nextImage = $sliderThumbs.eq(nextSlide);

          $productBadge.addClass('hidden');
          if ($nextImage.hasClass('js-main-image-thumb')) {
            $productBadge.removeClass('hidden');
          }
        });
      };

      var _init_thumb_carousel = function () {
        var $carousel = $tCarousel.$ele;
        var tSettings = {
          arrows: true,
          appendArrows: $('.carousel-arrows', $carousel),
          vertical: true,
          slide: '.js-product-thumb',
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false
        };
        if (isMediumUp) {
          $carousel.not('.slick-initialized').slick(tSettings);
          $carousel.slick('slickFilter', ':not(.hidden)');
        }
      };

      var _refresh_carousel = function () {
        var $carousel = $fsCarousel.$ele;

        $carousel.slick('slickFilter', ':not(.hidden)');
        $carousel.slick('setPosition');
        $(window).trigger('reInitZoom');
      };

      _init_slides_carousel();
      _init_thumb_carousel();
      $fsCarousel.$ele.on('refresh', _refresh_carousel);

      $('.js-product-thumb', $sppThumbnails).eq(0).addClass('active');

      $sliderThumbs = $(sliderThumbSelector, $container);
      $sliderThumbs.each(function (index) {
        $(this).data('slick-index', index);
      });
      $sliderThumbs.once().on('click', function (e) {
        var $sliderThumb = $(this);
        var index;

        e.preventDefault();

        // If already selected, ignore.
        if ($sliderThumb.hasClass('active')) {
          return;
        }
        if (isDesktop && imageZoom) {
          Drupal.behaviors.productImageFullV1._removeZoom();
        }

        // Pause video when selecting another thumbnail image
        $(document).trigger('videos.pause');

        $sliderThumbs.filter('.active').removeClass('active');
        $sliderThumb.addClass('active');

        // Change the slide showing according to this element's index
        index = $sliderThumb.data('slick-index');

        $fsCarousel.$ele.slick('slickGoTo', index);
        if (isDesktop && imageZoom) {
          Drupal.behaviors.productImageFullV1._initZoom();
        }
      });

      Unison.on('change', function (bp) {
        bp = Unison.fetch.now();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
        isMediumUp = parseInt(bp.width, 10) >= parseInt(bps.medium, 10);

        if (isMediumUp) {
          _init_thumb_carousel();
        } else {
          if ($tCarousel.$ele.hasClass('slick-initialized')) {
            $tCarousel.$ele.removeClass('slick-initialized');
            $tCarousel.$ele.slick('unslick');
          }
        }
        if (isDesktop) {
          Drupal.behaviors.productImageFullV1._initZoom();
        } else {
          Drupal.behaviors.productImageFullV1._removeZoom();
        }
      });
    },

    // @todo move this to private scope inside the attach
    reInitCarousel: function ($imageContainer, data, prodData, $product) {
      var thumbsSelector = '.js-product-thumbs';
      var slidesSelctor = '.js-spp-carousel';
      var $carouselSlides = $(slidesSelctor, $imageContainer);
      var $carouselThumbs = $(thumbsSelector, $imageContainer);
      var $productImageFull = $();
      var productImageFull = '';
      var newSlides = '';
      var newThumbs = '';
      var imageZoom = $imageContainer.attr('data-image-zoom');

      // Validate we've got a slick carousel
      if (!$carouselSlides.hasClass('slick-initialized')) {
        return;
      }

      // Verify and retreive prodData
      if (!prodData) {
        prodData = prodcat.data.getProduct(data.PRODUCT_ID);
      }

      // Parse new template for changing regions
      productImageFull = site.template.get({
        name: 'product_image_full',
        data: {
          defaultSku: data,
          PROD_RGN_NAME: prodData.PROD_RGN_NAME,
          VIDEO_POSTER_1: prodData.VIDEO_POSTER_1,
          VIDEO_SOURCE_1: prodData.VIDEO_SOURCE_1,
          VIDEO_POSTER_2: prodData.VIDEO_POSTER_2,
          VIDEO_SOURCE_2: prodData.VIDEO_SOURCE_2,
          VIDEO_POSTER_3: prodData.VIDEO_POSTER_3,
          VIDEO_SOURCE_3: prodData.VIDEO_SOURCE_3
        }
      });
      $productImageFull = $(productImageFull);
      newSlides = $productImageFull.find(slidesSelctor).html();
      newThumbs = $productImageFull.find(thumbsSelector).html();

      // Get and set current index
      lastIndex = $carouselSlides.slick('slickCurrentSlide');

      // Unslick the 2 carousels
      $carouselSlides.slick('unslick');

      if ($carouselThumbs.hasClass('slick-initialized')) {
        $carouselThumbs.removeClass('slick-initialized');
        $carouselThumbs.slick('unslick');
      }

      // Replace the html of each
      $carouselSlides.html(newSlides);
      if ($carouselThumbs.length) {
        $carouselThumbs.html(newThumbs);
      }

      // Restart the video setup
      var $vidElements = $('.js-yt-video-slider', $carouselSlides);

      if ($vidElements.length > 0) {
        this.vidSetup($vidElements, $carouselSlides);
      }

      // Write the new sku index
      $imageContainer.attr('data-sku-base-id', data.SKU_BASE_ID);

      // Refresh the slick slider if there is one
      this.initCarousel(
        {
          $ele: $carouselSlides
        },
        {
          $ele: $carouselThumbs
        },
        $imageContainer,
        imageZoom,
        $product
      );
      if (isDesktop && imageZoom) {
        this._removeZoom();
        this._initZoom();
      }
    },
    _initZoom: function (context) {
      var $largeImg = $('.js-product-slide.slick-active .product-full__carousel-slide-img');
      var $zoomWindow = $();
      var hasVideoImg = $largeImg.hasClass('product-full__carousel-slide-vid-img');

      // Turn off Zoom in quickshop and when on video-images
      if ($largeImg.parents('.js-quickshop').length > 0 || !!hasVideoImg) {
        return null;
      }

      $largeImg.elevateZoom({
        zoomType: 'inner',
        easing: 'true',
        onZoomedImageLoaded: function () {
          $zoomWindow = $('.zoomWindow', context);
          $zoomWindow.addClass('hidden');
          $('.zoomContainer', context)
            .once()
            .on('click', function () {
              $zoomWindow.toggleClass('hidden');
            });
        }
      });
    },
    _removeZoom: function () {
      var $largeImg = $('.js-gallery-img-large.active');

      $.removeData($largeImg, 'elevateZoom'); // remove zoom instance from image
      $('.zoomContainer').remove(); // remove zoom container from DOM
    },
    attach: function (context) {
      var self = this;
      if (self.attached) {
        return;
      }
      self.attached = true;

      var $modules = $('.product-full-v2', context);
      var imageZoom = $('.product-full__image').attr('data-image-zoom');
      var $vidElements = $('.js-yt-video-slider', context).once();

      if ($vidElements.length > 0) {
        self.vidSetup($vidElements, context);
      }

      // Initialize all carousels.
      $modules.each(function () {
        var $module = $(this);
        var $carouselContainer = $('.js-product-full__image', $module);
        var $carouselSlides = $('.js-spp-carousel', $carouselContainer);
        var $carouselThumbs = $('.js-product-thumbs', $carouselContainer);

        self.initCarousel(
          {
            $ele: $carouselSlides
          },
          {
            $ele: $carouselThumbs
          },
          $carouselContainer,
          imageZoom,
          $module
        );
      });
      if (isDesktop && imageZoom) {
        self._initZoom(context);
      }
    },
    vidSetup: function ($modules, context) {
      $modules.each(function () {
        vidInit($(this));
      });

      function vidInit($module) {
        var $moduleLink = $('.js-yt-video__module-clickable', $module);
        var $play = $('.js-video-play', $module);
        var $playIcon = $('.js-video-play-icon', $play);
        var $reveal = $('.js-yt-video-slider__reveal', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isFullScreen = false;

        $module.off('video.close').on('video.close', function () {
          // Pause youtube
          $('iframe', $module).remove();
          $module.removeClass('active');
        });

        $play.off('click.play').on('click.play', function (event) {
          event.preventDefault();
          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            provider: provider,
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal
          };

          $(document).trigger('videos.pause');

          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function () {
            var $videoContent = $(this).closest('.js-content-block');

            // When this video starts playing, let the content block handle the active state
            if ($videoContent.length > 0) {
              $videoContent.trigger('video.playing');
            }
            $module.addClass('active');
          });

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          }

          if (!!isFullScreen) {
            opts.openOverlay = true;
            opts.width = '100%';
            opts.maxWidth = '100%';
            opts.height = '100%';
            opts.maxHeight = '100%';
          }

          if ($moduleLink.length > 0) {
            opts.openLink = true;
          }
          if ($moduleLink.parents('.js-spp-carousel__slide').hasClass('slick-active')) {
            $(document).trigger('video.open', [opts]);
          }

          return false;
        });

        $playIcon.once().on('keydown', function (e) {
          e.preventDefault();
          if (e.keyCode === 13) {
            $play.trigger('click.play');
          }
        });

        $('.js-yt-video-slider__close', $module).on('click', function (event) {
          event.preventDefault();
          $(document).trigger('videos.pause');
          $module.trigger('video.close');
        });
      }
    },
    attached: false
  };

  // Listen for sku changes.
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(skuBaseId);
    var $imageContainer = $('.js-product-full__image', $product);

    if (!$imageContainer.length) {
      return;
    }

    // check against current sku | avoid rendering twice
    var currentSku = parseInt($imageContainer.attr('data-sku-base-id')) + '';

    if (skuBaseId === currentSku) {
      return;
    }

    var $modules = $('.js-product-full__image', $product);
    var imageZoom = $('.product-full__image').attr('data-image-zoom');

    if (!$modules.length) {
      return;
    }

    $modules.each(function () {
      var $carouselContainer = $(this);
      var $carouselSlides = $('.js-spp-carousel', $carouselContainer);
      var $carouselThumbs = $('.js-product-thumbs', $carouselContainer);

      Drupal.behaviors.productImageFullV1.initCarousel(
        {
          $ele: $carouselSlides
        },
        {
          $ele: $carouselThumbs
        },
        $carouselContainer,
        imageZoom,
        $product
      );
    });

    if (isDesktop && imageZoom) {
      Drupal.behaviors.productImageFullV1._initZoom();
    }
    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;

    Drupal.behaviors.productImageFullV1.reInitCarousel($imageContainer, data, prod, $product);
  });
  $(window).on('pageshow reInitZoom', function () {
    var bps = Unison.fetch.all();
    var bp = Unison.fetch.now();
    var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
    var $product = $('.js-product-full', document);

    if ($product.length < 1) {
      return;
    }

    if (isDesktop) {
      Drupal.behaviors.productImageFullV1._removeZoom();
      Drupal.behaviors.productImageFullV1._initZoom();
    }
  });
})(jQuery, window.prodcat || {}, window.site || {});
